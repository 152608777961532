import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { jwtDecode } from 'jwt-decode';
const AuthContext = createContext();

const userRole = 'user';
const adminRole = 'admin';

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useLocalStorage('authToken', null);
  const [userId, setUserId] = useLocalStorage('userId', null);
  const [userEmail, setUserEmail] = useLocalStorage('userEmail', null);
  const [selectedPet, setSelectedPet] = useState();
  const [userData, setUserData] = useState();

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async ({ data, email }) => {
    setUserEmail(email);
    setAuthToken(data.token);
    setUserId(data.id);
    navigate('/mypets');
  };

  // call this function when a pet is chosen
  const saveSelectedPet = async ({ data }) => {
    setSelectedPet(data);
  };

  // call this function when a pet is chosen
  const saveUserData = async ({ data }) => {
    setUserData(data);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setAuthToken(null);
    setUserId(null);
    setUserEmail(null);
    setSelectedPet(null);
    setUserData(null);
    navigate('/', { replace: true });
  };

  // call this function to check if a user's token has the admin role
  const isAdmin = () => {
    try {
      return jwtDecode(authToken).role === adminRole;
    } catch (e) {
      return false;
    }
  };

  const value = useMemo(
    () => ({
      authToken,
      userId,
      userEmail,
      selectedPet,
      userData,
      login,
      logout,
      saveSelectedPet,
      saveUserData,
      isAdmin,
    }),
    [authToken, userId, userEmail, selectedPet, userData]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
