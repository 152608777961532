export const first_name_validation = {
  name: 'firstName',
  label: 'firstName',
  type: 'text',
  id: 'firstName',
  placeholder: 'First Name',
  validation: {
    required: {
      value: true,
      message: 'First Name required',
    },
  },
};
export const last_name_validation = {
  name: 'lastName',
  label: 'lastName',
  type: 'text',
  id: 'lastName',
  placeholder: 'Last Name',
  validation: {
    required: {
      value: true,
      message: 'Last Name required',
    },
  },
};

export const email_validation = {
  name: 'email',
  label: 'email',
  type: 'email',
  id: 'email',
  placeholder: 'Email',
  validation: {
    required: {
      value: true,
      message: 'Email required',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Invalid email address',
    },
  },
};

export const password_validation = {
  name: 'password',
  label: 'password',
  type: 'password',
  id: 'password',
  placeholder: 'Password',
  validation: {
    required: {
      value: true,
      message: 'Password required',
    },
    minLength: {
      value: 6,
      message: 'Password min 6 characters',
    },
  },
};

export const confirm_password_validation = {
  name: 'confirm_password',
  label: 'confirm_password',
  type: 'password',
  id: 'confirm_password',
  placeholder: 'Confirm Password',
  validation: {
    required: {
      value: true,
      message: 'Password confirmation required',
    },
  },
};

export const phone_validation = {
  name: 'tel',
  label: 'phone',
  type: 'phone',
  id: 'phone',
  placeholder: 'Phone Number',
  validation: {
    required: {
      value: true,
      message: 'Phone Number required',
    },
    maxLength: {
      value: 13,
      message: 'Phone Number max 13 characters',
    },
  },
};

export const addressLine1_validation = {
  name: 'addressLine1',
  label: 'addressLine1',
  type: 'string',
  id: 'addressLine1',
  placeholder: 'Address Line 1',
  validation: {
    required: {
      value: true,
      message: 'Address required',
    },
  },
};

export const city_validation = {
  name: 'city',
  label: 'city',
  type: 'string',
  id: 'city',
  placeholder: 'City',
  validation: {
    required: {
      value: true,
      message: 'City required',
    },
  },
};

export const zip_validation = {
  name: 'zipCode',
  label: 'zipCode',
  type: 'num',
  id: 'zipCode',
  placeholder: 'Zip Code',
  validation: {
    required: {
      value: true,
      message: 'Zip Code required',
    },
    maxLength: {
      value: 5,
      message: 'Max 5 characters',
    },
  },
};
