import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading } from 'components/misc/Headings.js';
import {
  NavyContainer,
  Container,
  TealContainer,
} from 'components/misc/Layouts.js';
import Andy from '../assets/Andy.jpg';
import Elisa from '../assets/Elisa.jpg';
import Hannah from '../assets/hannah.jpg';

const HeadingRow = tw.div``;
const Column = tw.div`flex-col items-center p-16 max-w-full`;

const Heading = tw(SectionHeading)`text-left xl:text-5xl`;
const HeadingSecondary = tw(SectionHeading)`text-left xl:text-5xl text-center`;
const Name = tw(SectionHeading)`text-center text-2xl`;
const TeamDescription = tw.p`lg:text-xl text-lg leading-loose w-full font-body text-center p-4`;

const Description = tw.p`mt-4 lg:text-xl text-lg leading-loose w-full font-body`;
const ImageRow = tw.div`lg:ml-auto lg:mr-0 relative mt-16 lg:mt-0 flex flex-col lg:flex-row justify-center space-x-8`;
const ImageContainer = tw.div`relative z-40 text-center flex flex-col justify-center items-center basis-1/3 h-full mt-8`;
const Image = tw.img`sm:max-w-xs w-96 rounded-t sm:rounded relative z-20`;

const description =
  'Elisa is a veterinarian specializing in Internal Medicine. Within the first few years of her career, she experienced first-hand the frustration of not having access to previous medical records on her patients. This led to inefficiencies in the clinic, repeating expensive diagnostics, and even delays in diagnoses. Finally, she decided that she’d had enough of the current, clunky system of requesting veterinary records, and embarked on her dream to create a universal medical records database so that all pets, regardless of where they’ve seen a vet, would have their most important information in one place.';
const description2 =
  'Together with Andrew and Hannah, they’re making that dream a reality.';

const aboutDescription =
  'At Retriever Veterinary Records, we understand the frustrations and challenges faced by both veterinarians and pet parents when essential medical records are scattered or incomplete.';
const aboutDescription2 =
  "Our journey began with a pivotal moment—a young dachshund's upcoming road trip and the accompanying anxieties of navigating medical care while away from home, without comprehensive records. Inspired by this experience, we envisioned a solution: a unified platform where pet medical histories from primary care, emergency visits, and specialty appointments converge. Our mission is simple yet critical—to ensure your pet's complete medical history is accessible wherever they receive care.";
const aboutDescription3 =
  "Welcome to Retriever, where we're committed to making pet care seamless and stress-free with compassion and empathy.";

export default () => {
  return (
    <>
      <TealContainer>
        <Column>
          <HeadingRow>
            <Heading>About Us</Heading>
          </HeadingRow>
          <Description>{aboutDescription}</Description>
          <Description>{aboutDescription2}</Description>
          <Description>{aboutDescription3}</Description>
        </Column>
      </TealContainer>
      <NavyContainer>
        <Column>
          <HeadingRow>
            <Heading>Our Story</Heading>
          </HeadingRow>
          <Description>{description}</Description>
          <Description>{description2}</Description>
        </Column>
      </NavyContainer>
      <Container>
        <Column>
          <HeadingRow>
            <HeadingSecondary>Meet our Team!</HeadingSecondary>
          </HeadingRow>
          <ImageRow>
            <ImageContainer>
              <Image src={Elisa} />
              <Name>Elisa McEntee</Name>
              <TeamDescription>
                Co-founder, Board-certified Veterinary Internist
              </TeamDescription>
            </ImageContainer>

            <ImageContainer>
              <Image src={Andy} />
              <Name>Andrew O'Toole</Name>
              <TeamDescription>Co-founder, Software Engineer</TeamDescription>
            </ImageContainer>

            <ImageContainer>
              <Image src={Hannah} />
              <Name>Hannah Holzmann</Name>
              <TeamDescription>Co-founder, Software Engineer</TeamDescription>
            </ImageContainer>
          </ImageRow>
        </Column>
      </Container>
    </>
  );
};
