import tw from 'twin.macro';

export const Container = tw.div`relative bg-primary-purple text-primary-blue h-full w-full`;
export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 flex justify-center`;
export const ContentWithPaddingSm = tw.div`w-full py-8 lg:py-12`;
export const ContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-20 px-4 md:py-12 lg:py-16 xl:py-16 flex justify-center`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl = tw.div`max-w-screen-2xl mx-auto`;
export const BlueContainer = tw.div`relative bg-secondary-blue text-primary-purple min-w-full`;
export const NavyContainer = tw.div`relative bg-primary-blue text-primary-purple min-w-full`;
export const TealContainer = tw.div`relative bg-secondary-teal text-primary-blue min-w-full`;
export const PurpleContainer = tw.div`relative bg-primary-purple text-primary-blue min-w-full py-20 px-4 max-md:py-8 lg:py-16 xl:py-16 flex justify-center`;
