export const usStates = [
  { value: 'AL', label: 'AL - Alabama' },
  { value: 'AK', label: 'AK - Alaska' },
  { value: 'AZ', label: 'AZ - Arizona' },
  { value: 'AR', label: 'AR - Arkansas' },
  { value: 'CA', label: 'CA - California' },
  { value: 'CO', label: 'CO - Colorado' },
  { value: 'CT', label: 'CT - Connecticut' },
  { value: 'DE', label: 'DE - Delaware' },
  { value: 'DC', label: 'DC - District Of Columbia' },
  { value: 'FL', label: 'FL - Florida' },
  { value: 'GA', label: 'GA - Georgia' },
  { value: 'HI', label: 'HI - Hawaii' },
  { value: 'ID', label: 'ID - Idaho' },
  { value: 'IL', label: 'IL - Illinois' },
  { value: 'IN', label: 'IN - Indiana' },
  { value: 'IA', label: 'IA - Iowa' },
  { value: 'KS', label: 'KS - Kansas' },
  { value: 'KY', label: 'KY - Kentucky' },
  { value: 'LA', label: 'LA - Louisiana' },
  { value: 'ME', label: 'ME - Maine' },
  { value: 'MD', label: 'MD - Maryland' },
  { value: 'MA', label: 'MA - Massachusetts' },
  { value: 'MI', label: 'MI - Michigan' },
  { value: 'MN', label: 'MN - Minnesota' },
  { value: 'MS', label: 'MS - Mississippi' },
  { value: 'MO', label: 'MO - Missouri' },
  { value: 'MT', label: 'MT - Montana' },
  { value: 'NE', label: 'NE - Nebraska' },
  { value: 'NV', label: 'NV - Nevada' },
  { value: 'NH', label: 'NH - New Hampshire' },
  { value: 'NJ', label: 'NJ - New Jersey' },
  { value: 'NM', label: 'NM - New Mexico' },
  { value: 'NY', label: 'NY - New York' },
  { value: 'NC', label: 'NC - North Carolina' },
  { value: 'ND', label: 'ND - North Dakota' },
  { value: 'OH', label: 'OH - Ohio' },
  { value: 'OK', label: 'OK - Oklahoma' },
  { value: 'OR', label: 'OR - Oregon' },
  { value: 'PA', label: 'PA - Pennsylvania' },
  { value: 'PR', label: 'PR - Puerto Rico' },
  { value: 'RI', label: 'RI - Rhode Island' },
  { value: 'SC', label: 'SC - South Carolina' },
  { value: 'SD', label: 'SD - South Dakota' },
  { value: 'TN', label: 'TN - Tennessee' },
  { value: 'TX', label: 'TX - Texas' },
  { value: 'UT', label: 'UT - Utah' },
  { value: 'VT', label: 'VT - Vermont' },
  { value: 'VI', label: 'VI - Virgin Islands' },
  { value: 'VA', label: 'VA - Virginia' },
  { value: 'WA', label: 'WA - Washington' },
  { value: 'WV', label: 'WV - West Virginia' },
  { value: 'WI', label: 'WI - Wisconsin' },
  { value: 'WY', label: 'WY - Wyoming' },
];
