import React from 'react';
import tw from 'twin.macro';

const InputContainer = tw.div`relative py-1 mt-2`;
const StyledInput = tw.div`font-body w-full px-8 py-4 rounded-lg font-medium bg-secondary-blue border border-primary-purple placeholder-primary-blue text-base focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`;
const StyledError = tw.h4`flex justify-center text-red font-display`;
const RemoveFileButton = tw.button`rounded-full border border-primary-blue p-2`;

export const FileUploader = ({
  acceptedFiles,
  fileRejections,
  getRootProps,
  getInputProps,
  uploadMessage,
  file,
  removeFile,
}) => {
  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => {
            if ((e.code = 'file-too-large')) {
              return <li key={e.code}>File is larger than 2MB</li>;
            } else {
              return <li key={e.code}>{e.message}</li>;
            }
          })}
        </ul>
      </li>
    );
  });

  return (
    <InputContainer>
      <StyledInput {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{uploadMessage}</p>
      </StyledInput>
      <aside>
        {acceptedFileItems.length > 0 && (
          <h4>Accepted file: {acceptedFileItems}</h4>
        )}
        {fileRejectionItems.length > 0 && (
          <StyledError>Rejected file: {fileRejectionItems}</StyledError>
        )}
        {file && (
          <RemoveFileButton onClick={removeFile}>Remove File</RemoveFileButton>
        )}
      </aside>
    </InputContainer>
  );
};
