import React, { useState, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormData from 'form-data';
import { useAuth } from '../helpers/useAuth.js';
import { usStates } from '../static/UsStates';

import tw from 'twin.macro';
import styled from 'styled-components';

import { Container as ContainerBase } from 'components/misc/Layouts';
import { SectionHeading } from 'components/misc/Headings.js';
import { Input } from 'components/misc/InputWithValidation';
import { InputError } from 'components/misc/FormError';

import API from '../api/axios';
import { clinic_name_validation } from 'utils/clinicInputValidations';
import {
  addressLine1_validation,
  city_validation,
  email_validation,
  phone_validation,
  zip_validation,
} from 'utils/userInputValidations';

const Container = tw(
  ContainerBase
)`font-medium flex justify-center -m-8 w-screen my-4`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto`;
const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const TextContent = tw.div`flex flex-col text-center`;

const CheckBoxWrapper = tw.div`flex flex-row my-4`;

const ModalText = tw.p`text-base text-center lg:text-lg leading-relaxed text-primary-blue font-body`;

const SubmitButton = styled.button`
  ${tw`mt-5 font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
`;

const DeleteButton = styled.button`
  ${tw`mt-5 font-semibold bg-red text-secondary-blue w-full py-4 rounded-lg hover:bg-red transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
`;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  borderRadius: '0.5rem',
  boxShadow: 24,
  p: 4,
};

export default ({
  headingText = 'Edit Clinic',
  submitButtonText = 'Edit Clinic',
  // SubmitButtonIcon = Edit,
}) => {
  const { clinicId } = useParams();
  const navigate = useNavigate();
  const { authToken, isAdmin } = useAuth();

  const [apiError, setApiError] = useState(false);

  const methods = useForm();
  const { isSubmitting } = methods.formState;

  let formData = new FormData();

  useEffect(() => {
    API.get(`/clinics/${clinicId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        methods.reset(response.data);
      })
      .catch((err) => {
        setApiError(err.message);
        console.log(err);
        console.error(err);
      });
  }, []);

  const onSubmit = (data) => {
    const { name, email, phone, address, status } = data;
    const promises = Promise.all([
      API.put(
        `/clinics/${clinicId}`,
        {
          name,
          email,
          phone,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      ),
      API.put(
        `/clinics/${clinicId}/${status}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      ),
    ])
      .then(async (response) => {
        methods.reset();
        navigate(`/admin/clinics`);
      })
      .catch((err) => {
        setApiError(err.message);
        console.log(err);
        console.error(err);
      });
  };

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <Heading>{headingText}</Heading>
          <FormContainer>
            <FormProvider {...methods} autoComplete='off'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input
                  {...clinic_name_validation}
                  {...methods.register(clinic_name_validation.name)}
                />
                {isAdmin() ? (
                  <Input
                    type='string'
                    id='status'
                    name='status'
                    placeholder='Status'
                    dropdownValues={[
                      { value: 'unverified', label: 'Unverified' },
                      { value: 'pending', label: 'Pending' },
                      { value: 'verify', label: 'Verified' },
                      { value: 'suspend', label: 'Suspended' },
                    ]}
                    {...methods.register('status')}
                  />
                ) : (
                  <></>
                )}
                <Input
                  {...email_validation}
                  {...methods.register(email_validation.name)}
                  {...{ validation: { require: false } }}
                />
                <Input
                  {...phone_validation}
                  {...methods.register(phone_validation.name)}
                  {...{ validation: { require: false } }}
                />
                <Input
                  {...{
                    ...addressLine1_validation,
                    name: `address.${addressLine1_validation.name}`,
                  }}
                  disabled={true}
                />
                <Input
                  type='string'
                  id='addressLine2'
                  name='address.addressLine2'
                  placeholder='Address Line 2'
                  {...methods.register('address.addressLine2')}
                  disabled={true}
                />
                <Input
                  {...{
                    ...city_validation,
                    name: `address.${city_validation.name}`,
                  }}
                  disabled={true}
                />
                <Input
                  type='string'
                  id='state'
                  name='address.state'
                  placeholder='State'
                  dropdownValues={usStates}
                  disabled={true}
                />
                <Input
                  {...{
                    zip_validation,
                    name: `address.${zip_validation.name}`,
                  }}
                  disabled={true}
                />
                {apiError && <InputError message={apiError}></InputError>}
                <SubmitButton type='submit' disabled={isSubmitting}>
                  {/* <img src={SubmitButtonIcon} /> */}
                  <span className='text'>{submitButtonText}</span>
                </SubmitButton>
              </form>
            </FormProvider>
          </FormContainer>
        </TextContent>
      </TextColumn>
    </Container>
  );
};
