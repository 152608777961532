import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading } from 'components/misc/Headings.js';
import { PurpleContainer } from 'components/misc/Layouts.js';

const HeadingRow = tw.div`my-6`;
const Column = tw.div`flex-col items-center max-w-3xl max-md:px-4`;

const Heading = tw(SectionHeading)`text-left text-primary-blue xl:text-5xl`;

const Description = styled.p`
  ${tw`text-primary-blue font-body lg:text-xl text-lg`}
  p {
    ${tw`mt-4 leading-loose lg:text-lg text-base`}
  }
`;

const intro =
  'Your patient has been referred to you after treatment at another hospital. The pet is in your clinic, and you’re waiting on records to arrive that will provide critical information about what was done, and what’s wrong with this patient.';

export default () => {
  return (
    <PurpleContainer>
      <Column>
        <Description>{intro}</Description>
        <HeadingRow>
          <Heading>Sound Familiar?</Heading>
        </HeadingRow>
        <Description>
          <p>That’s because it’s all too common. </p>
          <p>
            As a small animal Internist, this is my day to day frustration, and
            I know I’m not alone - general practitioners, emergency doctors, and
            other specialties experience this too.{' '}
          </p>
          <p>
            And despite how commonplace electronic medical records are nowadays,
            there’s no way to connect the information of a single patient that
            is seen at multiple hospitals.{' '}
          </p>
          <p>
            That’s why I started Retriever Veterinary Records - to provide a
            single, read only database for your patient’s records.{' '}
          </p>
          <p>
            Right now, we’re soft launching with 100 pet parents around the
            country to see what works, what they want to see, and what doesn’t.
            The process of requesting, tagging and uploading records is our job,
            so they’ll be reviewed for completeness (no more attachments without
            the actual data, or pending urine culture without the final MIC!),
            but there won’t be any editing. That way, you and your clients know
            that the whole record is there. No more sorting through attachments,
            waiting on emails that won’t ever come, or explaining (for the
            millionth time) that despite best efforts, the records haven’t
            arrived.{' '}
          </p>
          <p>
            {' '}
            You may have patients that are using Retriever Veterinary Records -
            I’d love your feedback and thoughts about what works, and what we
            can improve. We also aim to create a veterinary database, so that
            you can use one login to view multiple patients. Stay tuned.
          </p>
          <p>
            If you’d like to offer feedback, have questions, or just want to
            chat, send me a message at elisa@retrieverrecords.vet From one
            colleague to another, thanks in advance for your support and
            participation!
          </p>{' '}
          <p>Elisa McEntee, DVM, DACVIM (SAIM)</p>
        </Description>
      </Column>
    </PurpleContainer>
  );
};
