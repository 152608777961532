import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { useParams } from 'react-router-dom';
import ReactRoundedImage from 'react-rounded-image';

import { css } from 'styled-components/macro'; //eslint-disable-line

import API from '../../api/axios';
import { useAuth } from '../../helpers/useAuth';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import DefaultDog from '../../assets/retriever_dog.svg';

import { Container as ContainerBase } from 'components/misc/Layouts';

const Container = tw(
  ContainerBase
)` flex-col font-body font-medium flex justify-center w-screen h-full bg-white text-primary-blue`;
const UserHeaderContainer = tw.div`flex w-full items-center justify-center text-2xl bg-white text-primary-blue pt-4 pb-2`;
const ColumnLeft = tw.div`w-1/2 text-xl md:text-2xl items-center text-right pr-2 md:pr-8 flex justify-end`;
const ColumnRight = tw.div`w-1/2 flex-col self-center text-sm md:text-lg`;
const Row = tw.div`flex flex-row`;
const Separation = tw.div`h-1`;
const ImageContainer = tw.div`mr-2`;

export const AdminHeader = ({ page, children }) => {
  const { width } = useWindowDimensions();

  const isMobileLayout = width < 640;

  return (
    <Container>
      <UserHeaderContainer>{`${page.toUpperCase()} DASHBOARD`}</UserHeaderContainer>
      <Separation></Separation>
      {children}
    </Container>
  );
};
