export const clinic_name_validation = {
  name: 'name',
  type: 'text',
  id: 'clinicName',
  placeholder: 'Clinic Name',
  validation: {
    required: {
      value: true,
      message: 'Clinic Name required',
    },
  },
};
