import React, { useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';

import tw from 'twin.macro';
import styled from 'styled-components';

import { Container as ContainerBase } from 'components/misc/Layouts';
import { SectionHeading } from 'components/misc/Headings.js';
import { Input } from 'components/misc/InputWithValidation';
import { usStates } from '../static/UsStates';

import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';
import API from '../api/axios';
import { clinic_name_validation } from 'utils/clinicInputValidations';
import {
  addressLine1_validation,
  city_validation,
  zip_validation,
} from 'utils/userInputValidations';

const Container = tw(
  ContainerBase
)`font-medium flex justify-center -m-8 w-screen my-4`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto`;
const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const TextContent = tw.div`flex flex-col text-center`;

const CheckBoxWrapper = tw.div`flex flex-row my-4`;

const SubmitButton = styled.button`
  ${tw`mt-5 font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
  headingText = 'Add A New Clinic',
  submitButtonText = 'Add Clinic',
  SubmitButtonIcon = PlusIcon,
}) => {
  const methods = useForm();
  const { isSubmitting } = methods.formState;
  const navigate = useNavigate();

  const userId = JSON.parse(localStorage.getItem('userId'));
  const authToken = JSON.parse(localStorage.getItem('authToken'));

  let formData = new FormData();

  const onSubmit = methods.handleSubmit((data) => {
    const { name, email, phone, address } = data;

    API.post(
      `/clinics`,
      {
        name,
        email,
        phone,
        address,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
      .then(async (response) => {
        methods.reset();
        navigate(`/admin/clinics`);
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      });
  });

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <Heading>{headingText}</Heading>
          <FormContainer>
            <FormProvider {...methods} autoComplete='off'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input {...clinic_name_validation} />
                <Input
                  {...{
                    ...addressLine1_validation,
                    name: `address.${addressLine1_validation.name}`,
                  }}
                />
                <Input
                  type='string'
                  id='addressLine2'
                  name='address.addressLine2'
                  placeholder='Address Line 2'
                />
                <Input
                  {...{
                    ...city_validation,
                    name: `address.${city_validation.name}`,
                  }}
                />
                <Input
                  type='string'
                  id='state'
                  name='address.state'
                  placeholder='State'
                  dropdownValues={usStates}
                />
                <Input
                  {...{
                    zip_validation,
                    name: `address.${zip_validation.name}`,
                  }}
                />
                <SubmitButton type='submit' disabled={isSubmitting}>
                  <SubmitButtonIcon className='icon' />
                  <span className='text'>{submitButtonText}</span>
                </SubmitButton>
              </form>
            </FormProvider>
          </FormContainer>
        </TextContent>
      </TextColumn>
    </Container>
  );
};
