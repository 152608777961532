import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import ReactRoundedImage from 'react-rounded-image';
import { useNavigate } from 'react-router-dom';

import API from '../api/axios';
import { UserHeader } from '../components/headers/UserHeader';
import { useAuth } from '../helpers/useAuth';
import useWindowDimensions from '../helpers/useWindowDimensions';

import Edit from '../assets/edit.svg';
import Plus from '../assets/plus.png';
import DefaultDog from '../assets/retriever_dog.svg';

const Content = tw.div`flex flex-col w-full my-10 flex-1`;
const ColumnRight = tw.div`pl-4 md:pl-12 w-1/2 flex-col self-center`;
const ColumnLeft = tw.div`flex w-1/2 text-xl justify-end self-center text-right`;
const PetRow = tw.div`flex flex-row max-sm:h-32 md:h-40 mb-4 cursor-pointer`;

const PetName = tw.div`max-sm:text-2xl text-4xl font-bold`;
const ImageContainer = tw.div`flex-shrink-0 self-center`;
const Image = tw.img`max-w-full w-8 md:w-12 relative mr-4 md:mr-12`;

export default () => {
  const { userId, authToken } = useAuth();
  const [allPets, setAllPets] = useState({});
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const isMobileLayout = width < 640;

  useEffect(() => {
    if (userId) {
      API.get(`/users/${userId}/pets`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          const { data } = response;
          setAllPets(data.pets);
        })
        .catch((err) => {
          console.log('incorrect credentials', err);
          console.error(err);
        });
    }
  }, []);

  const imageSize = isMobileLayout ? '100' : '140';

  return (
    <UserHeader>
      <Content>
        {allPets.length > 0 &&
          allPets.map((pet) => (
            <PetRow key={pet.id}>
              <ColumnLeft>
                <ImageContainer onClick={() => navigate(`/editPet/${pet.id}`)}>
                  <Image src={Edit} />
                </ImageContainer>
                <ImageContainer
                  onClick={() => navigate(`/medicalRecords/${pet.id}`)}
                >
                  <ReactRoundedImage
                    image={pet.profileImage || DefaultDog}
                    roundedColor='#0E1152'
                    imageWidth={imageSize}
                    imageHeight={imageSize}
                    roundedSize='8'
                    borderRadius='70'
                    hoverShadow
                  />
                </ImageContainer>
              </ColumnLeft>
              <ColumnRight>
                <PetName onClick={() => navigate(`/medicalRecords/${pet.id}`)}>
                  {pet.name}
                </PetName>
              </ColumnRight>
            </PetRow>
          ))}
        <PetRow key='add_pet_button'>
          <ColumnLeft>
            <ImageContainer onClick={() => navigate('/AddPet')}>
              <ReactRoundedImage
                image={Plus}
                roundedColor='#0E1152'
                imageWidth={imageSize}
                imageHeight={imageSize}
                roundedSize='8'
                borderRadius='70'
                hoverShadow
              />
            </ImageContainer>
          </ColumnLeft>
          <ColumnRight>
            <PetName>ADD PET</PetName>
          </ColumnRight>
        </PetRow>
      </Content>
    </UserHeader>
  );
};
