import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import emailjs from '@emailjs/browser';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { SectionHeading } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { useAuth } from '../helpers/useAuth';
import { UserHeader } from 'components/headers/UserHeader';
import { Input } from 'components/misc/InputWithValidation';

const Container = tw.div`relative bg-primary-purple text-primary-blue`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const SuccessMessage = tw.p`mt-16 text-lg md:text-base lg:text-xl leading-relaxed text-primary-blue font-body`;

const TextContent = tw.div`lg:py-8 text-center`;

const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 rounded-lg`;

export default ({
  headingText = 'Request Records',
  submitButtonText = 'Send',
  successMessageText = 'Your message has been received!',
}) => {
  const { userEmail, selectedPet, userData } = useAuth();
  const methods = useForm();
  const [showSuccess, setShowSuccess] = useState(false);

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const sendEmail = methods.handleSubmit((data) => {
    const { facility_location, visit_date, facility_name } = data;

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_RECORDS_TEMPLATE_ID,
        {
          facility_location,
          visit_date,
          facility_name,
          from_email: userEmail,
          from_name: userData?.firstName + ' ' + userData?.lastName,
          pet_name: selectedPet?.name,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setShowSuccess(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  });

  return (
    <UserHeader>
      <Container>
        <TextColumn>
          <TextContent>
            <Heading>{headingText}</Heading>
            {showSuccess ? (
              <SuccessMessage>{successMessageText}</SuccessMessage>
            ) : (
              <FormContainer>
                <FormProvider {...methods} noValidate autoComplete='off'>
                  <form onSubmit={sendEmail}>
                    <Input
                      type='text'
                      name='facility_name'
                      placeholder='Clinic/Hospital Name'
                      required
                      validation={{
                        required: {
                          value: true,
                          message: 'Veterinary Clinic required',
                        },
                      }}
                    />
                    <Input
                      type='date'
                      name='visit_date'
                      placeholder='Date of Visit'
                    />

                    <Input
                      type='text'
                      name='facility_location'
                      placeholder='Clinic/Hospital Address'
                    />
                    <SubmitButton type='submit'>
                      {submitButtonText}
                    </SubmitButton>
                  </form>
                </FormProvider>
              </FormContainer>
            )}
          </TextContent>
        </TextColumn>
      </Container>
    </UserHeader>
  );
};
