import React, { useState, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormData from 'form-data';
import moment from 'moment';

import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { Container as ContainerBase } from 'components/misc/Layouts';
import { SectionHeading } from 'components/misc/Headings.js';
import { Input } from 'components/misc/InputWithValidation';
import { InputError } from 'components/misc/FormError';
import {
  pet_name_validation,
  species_validation,
  breed_validation,
  neuter_status_validation,
  pet_alias_validation,
  pet_birthday_validation,
  pet_hospitals,
  pet_checkbox,
} from '../utils/petInputValidations';
import { FileUploader } from 'components/misc/FileUploader';

import API from '../api/axios';

const Container = tw(
  ContainerBase
)`font-medium flex justify-center -m-8 w-screen my-4`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto`;
const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const TextContent = tw.div`flex flex-col text-center`;

const CheckBoxWrapper = tw.div`flex flex-row my-4`;

const ModalText = tw.p`text-base text-center lg:text-lg leading-relaxed text-primary-blue font-body`;

const SubmitButton = styled.button`
  ${tw`mt-5 font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
`;

const DeleteButton = styled.button`
  ${tw`mt-5 font-semibold bg-red text-secondary-blue w-full py-4 rounded-lg hover:bg-red transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
`;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  borderRadius: '0.5rem',
  boxShadow: 24,
  p: 4,
};

export default ({
  headingText = 'Edit Your Pet',
  submitButtonText = 'Edit Pet',
  // SubmitButtonIcon = Edit,
  deleteButtonText = 'Delete Pet',
}) => {
  const { petId } = useParams();
  const navigate = useNavigate();

  const [petImage, setPetImage] = useState();
  const [apiError, setApiError] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const userId = JSON.parse(localStorage.getItem('userId'));
  const authToken = JSON.parse(localStorage.getItem('authToken'));
  const maxSize = 2097152;

  const methods = useForm();
  const { isSubmitting } = methods.formState;

  let formData = new FormData();

  const saveImage = useCallback((acceptedFiles) => {
    setPetImage(acceptedFiles);
  }, []);

  const removePetImage = (file) => {
    setPetImage();
    acceptedFiles.splice(0, acceptedFiles.length);
    fileRejections.splice(0, fileRejections.length);
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'image/jpeg': [],
      },
      maxFiles: 1,
      onDrop: saveImage,
      maxSize,
    });

  useEffect(() => {
    API.get(`/users/${userId}/pets/${petId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        methods.reset(response.data);
      })
      .catch((err) => {
        setApiError(err.message);
        console.log(err);
        console.error(err);
      });
  }, []);

  const onSubmit = (data) => {
    const {
      name,
      species,
      breed,
      intactStatus,
      aliases,
      hospitals,
      dateOfBirth,
    } = data;
    const dateFormatted = moment(dateOfBirth).format('YYYY-MM-DD');

    if (fileRejections.length > 0) {
      removePetImage();
    }
    API.put(
      `/users/${userId}/pets/${petId}`,
      {
        name,
        species,
        breed,
        intactStatus,
        aliases,
        hospitals,
        dateOfBirth: dateFormatted,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
      .then(async (response) => {
        const petId = response.data.id;

        if (petImage && petId) {
          formData.append('file', petImage[0]);
          await API.post(
            `users/${userId}/pets/${response.data.id}/uploadProfileImage`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
              },
            }
          )
            .then(() => {
              methods.reset();
              navigate(`/myPets`);
            })
            .catch((err) => {
              setApiError(err.message);
              console.log('image upload error', err);
              console.error(err);
            });
        } else {
          methods.reset();
          navigate(`/myPets`);
        }
      })
      .catch((err) => {
        setApiError(err.message);
        console.log(err);
        console.error(err);
      });
  };

  const deletePet = () => {
    API.delete(`/users/${userId}/pets/${petId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(() => {
        methods.reset();
        navigate(`/myPets`);
      })
      .catch((err) => {
        setApiError(err.message);
        console.log('deletion error', err);
        console.error(err);
      });
  };

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <Heading>{headingText}</Heading>
          <FormContainer>
            <FormProvider {...methods} autoComplete='off'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input {...pet_name_validation} />
                <Input {...species_validation} />
                <Input {...breed_validation} />
                <Input {...neuter_status_validation} />
                <Input {...pet_alias_validation} />
                <Input {...pet_birthday_validation} />
                <Input {...pet_hospitals} />
                <FileUploader
                  {...{
                    acceptedFiles,
                    fileRejections,
                    getRootProps,
                    getInputProps,
                  }}
                  file={petImage}
                  removeFile={removePetImage}
                  uploadMessage='Drag & Drop or click to upload an image of your pet here! (JPG Only)'
                />
                <CheckBoxWrapper>
                  <Input {...pet_checkbox} />
                  By checking this box, I permit Retriever Veterinary Records to
                  communicate with veterinary clinics on my behalf and request
                  my pet’s records.
                </CheckBoxWrapper>
                {apiError && <InputError message={apiError}></InputError>}
                <SubmitButton
                  type='submit'
                  disabled={isSubmitting || fileRejections.length > 0}
                >
                  {/* <img src={SubmitButtonIcon} /> */}
                  <span className='text'>{submitButtonText}</span>
                </SubmitButton>
                <DeleteButton
                  type='button'
                  onClick={() => setIsCancelModalOpen(true)}
                >
                  {/* <img src={SubmitButtonIcon} /> */}
                  <span className='text'>{deleteButtonText}</span>
                </DeleteButton>
                <Modal
                  open={isCancelModalOpen}
                  onClose={() => setIsCancelModalOpen(false)}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={modalStyle}>
                    <ModalText>
                      Are you sure you want to delete this pet? Doing so will
                      also remove their records.
                    </ModalText>
                    <DeleteButton type='button' onClick={deletePet}>
                      <span className='text'>{deleteButtonText}</span>
                    </DeleteButton>
                  </Box>
                </Modal>
              </form>
            </FormProvider>
          </FormContainer>
        </TextContent>
      </TextColumn>
    </Container>
  );
};
