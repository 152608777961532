import { Navigate } from 'react-router-dom';
import { useAuth } from '../helpers/useAuth';

export const ProtectedRoute = ({ children }) => {
  const { authToken } = useAuth();
  if (!authToken || authToken.isAdmin) {
    // user is not authenticated
    return <Navigate to='/login' />;
  }
  return children;
};
