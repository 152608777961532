import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import {
  BlueContainer,
  ContentWithPaddingSm,
} from 'components/misc/Layouts.js';
import { recordIcons } from '../../static/recordIcons';

const Subheading = tw(SubheadingBase)`text-center mb-3 text-lg`;
const Description = tw(SectionDescription)`text-center mx-auto text-base`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex items-center lg:items-stretch flex-row flex-wrap lg:justify-evenly justify-evenly max-w-screen-xl mx-auto`}
`;
const ImgColumn = styled.div`
  ${tw`max-w-xs`}
`;

const Column = styled.div`flex-col`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-6 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-secondary-blue leading-none hocus:text-secondary-blue transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

export default ({
  heading = "Consolidate all of your pet's most important information",
  subheading = '',
  description = '',
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <BlueContainer>
      <ContentWithPaddingSm>
        <Column>
          {heading && <Subheading>{heading}</Subheading>}
          {description && <Description>{description}</Description>}
          <ThreeColumnContainer>
            {recordIcons.map((card, i) => (
              <ImgColumn key={i}>
                <Card>
                  <span className='imageContainer' css={imageContainerCss}>
                    <img src={card.icon} alt='' css={imageCss} />
                  </span>
                  <Description>{card.name}</Description>
                </Card>
              </ImgColumn>
            ))}
          </ThreeColumnContainer>
        </Column>
      </ContentWithPaddingSm>
    </BlueContainer>
  );
};
