import tw from 'twin.macro';

import { MdError } from 'react-icons/md';

const StyledError = tw.div`flex justify-center text-red font-display`;
const ErrorMessage = tw.div`ml-1`;

export const InputError = ({ message }) => {
  return (
    <StyledError>
      <MdError />
      <ErrorMessage>{message}</ErrorMessage>
    </StyledError>
  );
};
