import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { Container as ContainerBase } from 'components/misc/Layouts';
import { Input } from 'components/misc/InputWithValidation';
import {
  first_name_validation,
  last_name_validation,
  email_validation,
  password_validation,
  confirm_password_validation,
  phone_validation,
  addressLine1_validation,
  city_validation,
  zip_validation,
} from '../utils/userInputValidations';

import { usStates } from '../static/UsStates';
import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';
import API from '../api/axios';
import { Subheading } from 'components/misc/Headings';

const Container = tw(
  ContainerBase
)`font-medium flex justify-center w-screen p-4`;

const HeadingInfoContainer = tw.div`items-center max-w-lg text-lg`;
const FormContainer = tw.div`text-sm flex flex-col max-w-sm mx-auto`;
const Heading = tw.h1`text-3xl xl:text-4xl font-extrabold text-primary-blue mb-4`;

const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const TextContent = tw.div`flex flex-col text-center`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
  headingText = 'Sign Up For Retriever',
  subHeadingText = 'In order for us to start collecting and organizing your pet’s medical records, we need to know a little bit more about you!',
  submitButtonText = 'Sign Up',
  SubmitButtonIcon = SignUpIcon,
  tosUrl = '#',
  privacyPolicyUrl = '#',
}) => {
  const methods = useForm();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  const onSubmit = methods.handleSubmit((data) => {
    const {
      firstName,
      lastName,
      email,
      password,
      tel,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
    } = data;

    API.post(
      '/users/',
      {
        firstName,
        lastName,
        email,
        password,
        phone: tel,
        address: {
          addressLine1,
          addressLine2,
          city,
          state,
          zipCode,
        },
      },
      { headers: { 'Content-Type': 'application/json' } }
    )
      .then(async (response) => {
        setSuccess(true);
        methods.reset();
        navigate('/login');
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      });
  });

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <HeadingInfoContainer>
            <Heading>{headingText}</Heading>
            <Subheading>{subHeadingText}</Subheading>
          </HeadingInfoContainer>
          <FormContainer>
            <FormProvider {...methods} noValidate autoComplete='off'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input {...first_name_validation} />
                <Input {...last_name_validation} />
                <Input {...email_validation} />
                <Input {...password_validation} />
                <Input
                  {...confirm_password_validation}
                  {...methods.register('confirm_password', {
                    required: true,
                    validate: (val) => {
                      if (methods.watch('password') !== val) {
                        return 'Your passwords do not match';
                      }
                    },
                  })}
                />
                <Input {...phone_validation} />
                <Input {...addressLine1_validation} />
                <Input
                  type='string'
                  id='addressLine2'
                  name='addressLine2'
                  placeholder='Address Line 2'
                />
                <Input {...city_validation} />
                <Input
                  type='string'
                  id='state'
                  name='state'
                  placeholder='State'
                  dropdownValues={usStates}
                />
                <Input {...zip_validation} />
                <SubmitButton type='submit'>
                  <SubmitButtonIcon className='icon' />
                  <span className='text'>{submitButtonText}</span>
                </SubmitButton>
                <p tw='mt-6 text-xs text-secondary-blue text-center'>
                  I agree to abide by Retriever's{' '}
                  <a
                    href={tosUrl}
                    tw='border-b border-secondary-blue border-dotted'
                  >
                    Terms of Service
                  </a>{' '}
                  and its{' '}
                  <a
                    href={privacyPolicyUrl}
                    tw='border-b border-secondary-blue border-dotted'
                  >
                    Privacy Policy
                  </a>
                </p>
              </form>
            </FormProvider>
            <p tw='mt-8 text-sm text-secondary-blue text-center'>
              Already have an account?{' '}
              <Link
                to='/login'
                tw='border-b border-secondary-blue border-dotted'
              >
                Log In
              </Link>
            </p>
          </FormContainer>
        </TextContent>
      </TextColumn>
    </Container>
  );
};
