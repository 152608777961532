import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { useParams } from 'react-router-dom';
import ReactRoundedImage from 'react-rounded-image';

import { css } from 'styled-components/macro'; //eslint-disable-line

import API from '../../api/axios';
import { useAuth } from '../../helpers/useAuth';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import DefaultDog from '../../assets/retriever_dog.svg';

import { Container as ContainerBase } from 'components/misc/Layouts';

const Container = tw(
  ContainerBase
)` flex-col font-body font-medium flex justify-center w-screen h-full bg-white text-primary-blue`;
const UserHeaderContainer = tw.div`flex w-full bg-white text-primary-blue pt-4 pb-2`;
const ColumnLeft = tw.div`w-1/2 text-xl md:text-2xl items-center text-right pr-2 md:pr-8 flex justify-end`;
const ColumnRight = tw.div`w-1/2 flex-col self-center text-sm md:text-lg`;
const Row = tw.div`flex flex-row`;
const Separation = tw.div`h-1 bg-primary-blue`;
const ImageContainer = tw.div`mr-2`;

export const UserHeader = ({ children }) => {
  const { width } = useWindowDimensions();

  const isMobileLayout = width < 640;

  const { userId, authToken, saveSelectedPet, saveUserData, isAdmin } =
    useAuth();
  const [userData, setUserData] = useState({});
  const [petData, setPetData] = useState();
  const { petId } = useParams();

  useEffect(() => {
    if (userId) {
      API.get(`/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(async (response) => {
          const { data } = response;
          setUserData(data);
          saveUserData({ data });
        })
        .catch((err) => {
          console.log('incorrect credentials', err);
          console.error(err);
        });

      if (petId) {
        API.get(`users/${userId}/pets/${petId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        })
          .then(async (response) => {
            const { data } = response;
            setPetData(data);
            await saveSelectedPet({ data });
          })
          .catch((err) => {
            console.log('incorrect credentials', err);
            console.error(err);
          });
      }
    }
  }, [userId]);

  return (
    <Container>
      <UserHeaderContainer>
        {petData?.name && (
          <>
            <ColumnLeft>
              <ImageContainer>
                <ReactRoundedImage
                  image={petData.profileImage || DefaultDog}
                  roundedColor='#0E1152'
                  imageWidth='60'
                  imageHeight='60'
                  roundedSize='3'
                  borderRadius='70'
                />
              </ImageContainer>
              {petData.name}
            </ColumnLeft>
            <ColumnRight>
              <Row>DOB: {petData.dateOfBirth}</Row>
              <Row>
                {petData.intactStatus} {petData.breed}
              </Row>
            </ColumnRight>
          </>
        )}
        {(!petData || !isMobileLayout) && (
          <>
            <ColumnLeft>
              {userData.firstName} {userData.lastName} {isAdmin && '(ADMIN)'}
            </ColumnLeft>
            <ColumnRight>
              <Row>P: {userData.phone}</Row>
              <Row>E: {userData.email}</Row>
            </ColumnRight>
          </>
        )}
      </UserHeaderContainer>
      <Separation></Separation>
      {children}
    </Container>
  );
};
