import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FormData from 'form-data';
import moment from 'moment';

import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { Container as ContainerBase } from 'components/misc/Layouts';
import { Input } from 'components/misc/InputWithValidation';
import {
  file_type_validation,
  visit_date_validation,
  pet_hospital_validation,
} from '../utils/fileUploadValidations';
import { SectionHeading } from 'components/misc/Headings.js';

import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';
import API from '../api/axios';
import { useAuth } from '../helpers/useAuth';
import { InputError } from '../components/misc/FormError';

const Container = tw(ContainerBase)`font-medium flex justify-center`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-w-lg mx-auto mb-16`;
const Heading = tw(
  SectionHeading
)`my-4 text-primary-blue text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;
const TextContent = tw.div`flex flex-col text-center`;

const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-blue text-secondary-blue w-full py-4 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

const DeleteButton = styled.button`
  ${tw`mt-5 font-semibold bg-red text-secondary-blue w-full py-4 rounded-lg hover:bg-red transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
  headingText = 'Edit File',
  submitButtonText = 'Edit File',
  SubmitButtonIcon = PlusIcon,
  deleteButtonText = 'Delete File',
}) => {
  const { userId, authToken } = useAuth();
  const methods = useForm();
  const navigate = useNavigate();
  const { isSubmitting } = methods.formState;

  const { petId, fileId } = useParams();
  const [fileData, setFileData] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    API.get(`/users/${userId}/pets/${petId}/medicalRecords/${fileId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        methods.reset(response.data);
        setFileData(response.data);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        console.log(err);
        console.error(err);
      });
  }, []);

  const onSubmit = (data) => {
    const { type, facility, dateOfRecord } = data;

    const dateFormatted = moment(dateOfRecord).format('YYYY-MM-DD');

    const formData = new FormData();
    formData.append('type', type);
    formData.append('facility', facility);
    formData.append('dateOfRecord', dateFormatted);

    API.put(
      `/users/${userId}/pets/${petId}/medicalRecords/${fileId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      }
    )
      .then(async (response) => {
        methods.reset();
        navigate(`/medicalRecords/${petId}`);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        console.log(err);
        console.error(err);
      });
  };

  const deleteFile = () => {
    API.delete(`/users/${userId}/pets/${petId}/medicalRecords/${fileId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then(() => {
        methods.reset();
        navigate(`/medicalRecords/${petId}`);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        console.log('deletion error', err);
        console.error(err);
      });
  };

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <Heading>{headingText}</Heading>
          <FormContainer>
            <FormProvider {...methods} autoComplete='off'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div>{fileData?.documentLink}</div>
                <Input {...file_type_validation} control={methods.control} />
                <Input {...visit_date_validation} />
                <Input {...pet_hospital_validation} />
                {errorMessage && <InputError message={errorMessage} />}
                <SubmitButton type='submit' disabled={isSubmitting}>
                  <SubmitButtonIcon className='icon' />
                  <span className='text'>{submitButtonText}</span>
                </SubmitButton>
                <DeleteButton type='button' onClick={deleteFile}>
                  {/* <img src={SubmitButtonIcon} /> */}
                  <span className='text'>{deleteButtonText}</span>
                </DeleteButton>
              </form>
            </FormProvider>
          </FormContainer>
        </TextContent>
      </TextColumn>
    </Container>
  );
};
