import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import emailjs from '@emailjs/browser';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { SectionHeading } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { Input } from 'components/misc/InputWithValidation';

const Container = tw(ContainerBase)`relative`;
const TextColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto`;

const SuccessMessage = tw.p`mt-16 text-lg md:text-base lg:text-xl leading-relaxed text-primary-blue font-body`;

const TextContent = tw.div`lg:py-8 text-center`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const FormContainer = tw.div`text-sm flex flex-col max-sm:max-w-sm max-sm:max-w-sm max-w-lg mx-auto`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  heading = 'Contact us',
  submitButtonText = 'Send',
  successMessageText = 'Your message has been received!',
}) => {
  const methods = useForm();
  const [showSuccess, setShowSuccess] = useState(false);

  const sendEmail = methods.handleSubmit((data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID,
        data,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setShowSuccess(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  });

  return (
    <Container>
      <TextColumn>
        <TextContent>
          <Heading>{heading}</Heading>
          {showSuccess ? (
            <SuccessMessage>{successMessageText}</SuccessMessage>
          ) : (
            <FormContainer>
              <FormProvider {...methods} noValidate autoComplete='off'>
                <form onSubmit={sendEmail}>
                  <Input
                    type='email'
                    name='from_email'
                    placeholder='Email Address'
                    validation={{
                      required: {
                        value: true,
                        message: 'Email required',
                      },
                    }}
                  />
                  <Input type='text' name='from_name' placeholder='Full Name' />
                  <Input
                    type='text'
                    name='from_petName'
                    placeholder="Pet's Name"
                  />
                  <Input type='text' name='subject' placeholder='Location' />
                  <Input
                    name='message'
                    type='text'
                    isTextArea
                    placeholder='Your Message Here'
                  />
                  <SubmitButton type='submit'>{submitButtonText}</SubmitButton>
                </form>
              </FormProvider>
            </FormContainer>
          )}
        </TextContent>
      </TextColumn>
    </Container>
  );
};
