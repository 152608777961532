import exams_icon from '../assets/icons-dark/exams_icon.svg';
import imaging_icon from '../assets/icons-dark/imaging_icon.svg';
import labwork_icon from '../assets/icons-dark/labwork_icon.svg';
import other_icon from '../assets/icons-dark/other_icon.svg';
import prescriptions_icon from '../assets/icons-dark/prescriptions_icon.svg';
import procedure_icon from '../assets/icons-dark/procedure_icon.svg';
import vaccines_icon from '../assets/icons-dark/vaccines_icon.svg';

export const recordIcons = [
  { name: 'Exams', icon: exams_icon },
  { name: 'Imaging', icon: imaging_icon },
  { name: 'Labwork', icon: labwork_icon },
  { name: 'Prescriptions', icon: prescriptions_icon },
  { name: 'Procedures', icon: procedure_icon },
  { name: 'Vaccines', icon: vaccines_icon },
  { name: '+ More', icon: other_icon },
];
