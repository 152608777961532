import React from 'react';
import Header from 'components/headers/PageHeader.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';

// import Footer from 'components/footers/FiveColumnWithInputForm.js';

const LayoutContainer = tw.div`flex flex-col min-h-screen h-full w-full`;

export default ({ children }) => {
  return (
    <LayoutContainer>
      <Header />
      <AnimationRevealPage>{children}</AnimationRevealPage>
      {/* <Footer /> */}
    </LayoutContainer>
  );
};
