import React, { useEffect, useState, useRef } from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import styled from 'styled-components';

import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

import API from '../api/axios';
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';

import { useAuth } from '../helpers/useAuth';
import { AdminHeader } from 'components/headers/AdminHeader';
import { CircularProgress } from '@mui/material';

const Container = tw.div`flex flex-col w-full flex-1 h-full px-4 min-h-[600px]`;
const TableContainer = tw.div`m-0 w-full sm:mx-4 sm:my-4 text-primary-blue shadow sm:rounded-lg flex justify-center`;

const RecordContainer = tw.div`flex md:flex-row max-sm:flex-col text-primary-blue`;

const SearchContainer = tw.div`flex flex-row mt-5 gap-4 justify-end`;
const PrimaryButton = styled.button`
  ${tw`tracking-wide font-body lg:text-lg font-semibold bg-primary-blue text-center text-secondary-blue lg:w-56 lg:py-4 px-8 rounded-lg hover:bg-primary-purple transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`lg:w-6 lg:h-6 -ml-2 max-sm:hidden`}
  }
  .text {
    ${tw`lg:ml-3`}
  }
`;

const Input = tw.input`w-64 px-8 py-4 rounded-lg font-medium bg-white border border-secondary-blue placeholder-secondary-blue focus:outline-none focus:border-secondary-blue focus:bg-white mt-5 first:mt-0`;

const LinkContainer = tw.div`flex flex-row gap-4 underline`;

const DataTableStyled = styled(DataTable)`
  background-color: white;

  .p-datatable-wrapper {
    @media (min-width: 1024px) {
      min-width: 50rem;
    }
    @media (min-width: 1280px) {
      min-width: 60rem;
    }
  }

  .p-datatable-thead {
    border-bottom: navy 2px solid;
    background-color: white;
    ${tw`md:text-xl max-sm:text-sm`};
  }

  .p-datatable-tbody {
    ${tw`font-body md:text-lg max-sm:text-sm`}
  }

  .p-selectable-row {
    ${tw`border-y py-4`}
  }

  .p-datatable-emptymessage {
    ${tw`text-center`}
  }
`;

export default ({
  emptyMessage = 'No records have been uploaded yet.',
  PrimaryButtonIcon = PlusIcon,
}) => {
  const tooltipRef = useRef(null);
  const { userId, authToken } = useAuth();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState('');
  const [searching, setSearching] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState();

  useEffect(() => {
    API.get(`/users`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((getUsersRes) => {
        const users = getUsersRes.data.map((u) => {
          u.pets = [];
          return u;
        });
        const userIds = users.map((u) => u.id);
        Promise.all(
          userIds.map((userId) =>
            API.get(`/users/${userId}/pets`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
            }).then((res) => {
              const { data: obj } = res;
              const { pets } = obj;
              console.log('pet res:', pets);
              if (pets.length > 0) {
                users[userIds.indexOf(userId)].pets = pets;
              }
            })
          )
        )
          .then(() => {
            setUsers(users);
          })
          .catch((err) => {
            console.error('get all pets err:', err);
          });
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      })
      .finally(() => {
        setSearching(false);
      });
  }, []);

  useEffect(() => {
    tooltipRef.current && tooltipRef.current.updateTargetEvents();
  }, [filteredFiles]);

  const searchByName = () => {
    if (searchInput === '') {
      return;
    }

    setSearching(true);

    // TODO: Endpoint not implemented yet.
    API.get(`/users/search?name=${encodeURIComponent(searchInput)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        setUsers(response.data.users);
      })
      .catch((err) => {
        console.log(err);
        console.error(err);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const ownerName = (rowData) => {
    return <div>{rowData.firstName + ' ' + rowData.lastName}</div>;
  };

  const pets = (rowData) => {
    if (rowData.pets.length === 0) {
      return <i tw='text-secondary-blue'>No pets added</i>;
    }

    return (
      <LinkContainer>
        {rowData.pets.map((pet) => (
          <Link to={'/medicalRecords/' + pet.id} tw='p-4'>
            {pet.name}
          </Link>
        ))}
      </LinkContainer>
    );
  };

  return (
    <AdminHeader page='users'>
      <Container>
        <SearchContainer>
          <Input
            placeholder='Search by Owner Email'
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            onSubmit={searchByName}
          />
          <PrimaryButton onClick={searchByName} css={tw`rounded-full`}>
            <span className='text'>Search User</span>
          </PrimaryButton>
        </SearchContainer>
        <RecordContainer>
          {searching ? (
            <>
              <CircularProgress></CircularProgress>
              Searching...
            </>
          ) : (
            <TableContainer>
              <Tooltip
                ref={tooltipRef}
                autoHide={false}
                target='.doc-img'
                mouseTrack
                mouseTrackLeft={10}
              />
              <DataTableStyled
                value={users}
                stripedRows
                emptyMessage={emptyMessage}
                scrollable
                scrollHeight='600px'
              >
                <Column
                  field='ownerName'
                  header='Owner Name'
                  sortable
                  style={{ width: '20%' }}
                  body={ownerName}
                ></Column>
                <Column
                  field='pets'
                  header='Pets'
                  style={{ width: '45%' }}
                  body={pets}
                ></Column>
              </DataTableStyled>
            </TableContainer>
          )}
        </RecordContainer>
      </Container>
    </AdminHeader>
  );
};
