export const pet_name_validation = {
  name: 'name',
  type: 'text',
  id: 'petName',
  placeholder: 'Pet Name',
  validation: {
    required: {
      value: true,
      message: 'Pet Name required',
    },
  },
};
export const species_validation = {
  name: 'species',
  type: 'text',
  id: 'species',
  placeholder: 'Species',
  dropdownValues: [
    { value: null, label: 'Species' },
    { value: 'cat', label: 'Cat' },
    { value: 'dog', label: 'Dog' },
  ],
  validation: {
    required: {
      value: true,
      message: 'Species required',
    },
  },
};

export const breed_validation = {
  name: 'breed',
  type: 'text',
  id: 'breed',
  placeholder: 'Breed (optional)',
};

export const neuter_status_validation = {
  name: 'intactStatus',
  type: 'text',
  id: 'intactStatus',
  dropdownValues: [
    { value: null, label: 'Sex and spay/neuter status' },
    { value: 'FS', label: 'Female Spayed' },
    { value: 'FI', label: 'Female Intact' },
    { value: 'MN', label: 'Male Neutered' },
    { value: 'MI', label: 'Male Intact' },
  ],
  placeholder: 'Sex and spay/neuter status',
  validation: {
    required: {
      value: true,
      message: 'Sex and spay/neuter status required',
    },
  },
};

export const pet_alias_validation = {
  name: 'aliases',
  type: 'text',
  id: 'aliases',
  isTextArea: true,
  placeholder:
    'Any aliases for your pet (ie other names they could be known by at a veterinarian)?',
};

export const pet_birthday_validation = {
  name: 'dateOfBirth',
  type: 'date',
  id: 'dateOfBirth',
  placeholder: 'Pet’s date of birth, or closest approximation',
};

export const pet_hospitals = {
  name: 'hospitals',
  type: 'text',
  id: 'hospitals',
  isTextArea: true,
  placeholder:
    'Please list all known veterinary hospitals/clinics and location if applicable (ie BluePearl Midtown)*',
  validation: {
    required: {
      value: true,
      message: 'Pet Hospital/clinic required',
    },
  },
};

export const pet_checkbox = {
  name: 'petCheckbox',
  type: 'checkbox',
  id: 'petCheckbox',
  validation: {
    required: {
      value: true,
      message: 'Required',
    },
  },
};
