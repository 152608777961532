export const file_type_validation = {
  name: 'type',
  label: 'type',
  type: 'text',
  id: 'type',
  options: [
    { value: 'exam', label: 'Exam' },
    { value: 'imaging', label: 'Imaging' },
    { value: 'labwork', label: 'Labwork' },
    { value: 'vaccines', label: 'Vaccines' },
    { value: 'prescriptions', label: 'Prescriptions' },
    { value: 'procedure', label: 'Procedure' },
    { value: 'other', label: 'Other' },
  ],
};

export const visit_date_validation = {
  name: 'dateOfRecord',
  label: 'dateOfRecord',
  type: 'date',
  id: 'dateOfRecord',
  placeholder: 'Date of visit',
  validation: {
    required: {
      value: true,
      message: 'Date of visit required',
    },
  },
};

export const pet_hospital_validation = {
  name: 'facility',
  label: 'facility',
  type: 'text',
  id: 'facility',
  placeholder: 'Veterinary Clinic',
  validation: {
    required: {
      value: true,
      message: 'Veterinary Clinic required',
    },
  },
};
